import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppStocks from './AppStocks';
import MainPage from './MainPage';
import './socket';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppClientAddAddress from './client-map-address/App';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainPage />,
    },
    {
        path: '/:orderGuid',
        element: <App />,
    },
    {
        path: '/stocks',
        element: <AppStocks />,
    },
    {
        path: '/address',
        element: <AppClientAddAddress />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
