import React, { useCallback, useState } from 'react';
import './App.css';
import { Map2GIS, HtmlMarker2GIS } from './geo';
import { ReactComponent as SkladIcon } from './assets/sklad.svg';
import { ReactComponent as HomeIcon } from './assets/home.svg';
import { useSearchParams } from 'react-router-dom';

export const courierIcon = `<svg viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg">
<circle cx="18.984" cy="18.524" r="18" fill="#A375DE"/>
<path d="m17.367 15.394 0.0357 2.1473c0.0335 2.0228 0.0496 3.0346 0.7056 3.6622 0.6557 0.6287 1.6949 0.6266 3.7727 0.6245l2.2044-0.0037c2.0779-2e-3 3.1168-0.0031 3.7522-0.6318 0.6357-0.6298 0.6196-1.6416 0.5851-3.6647l-0.0349-2.1459c-0.0332-2.0239-0.0504-3.036-0.7053-3.6633-0.6568-0.6289-1.696-0.6268-3.774-0.6237l-2.204 0.0015c-2.0778 0.0021-3.117 0.0042-3.7516 0.6342-0.6354 0.6287-0.6194 1.6406-0.5859 3.6634z" fill="white"/>
<path d="m10.498 9.6204c0.0547-0.09484 0.1276-0.17795 0.2146-0.24459 0.0869-0.06663 0.1861-0.11549 0.2919-0.14377 0.1058-0.02827 0.2161-0.03542 0.3246-0.02102 0.1086 0.01439 0.2133 0.05004 0.3081 0.10491l1.7006 0.98155c0.4514 0.2574 0.8284 0.6274 1.0943 1.0739 0.2659 0.4464 0.4115 0.9542 0.4228 1.4737l0.1478 8.974 0.0181 0.6313c0.615 0.4311 1.0769 1.0589 1.2845 1.7874l0.3601-0.017 10.183-0.0119c0.1095-2e-4 0.2179 0.0212 0.3191 0.063 0.1012 0.0417 0.1932 0.1029 0.2707 0.1802 0.0776 0.0773 0.1391 0.1691 0.1812 0.2702 0.042 0.101 0.0638 0.2094 0.0639 0.3188 2e-4 0.1095-0.0212 0.2179-0.0629 0.3191s-0.103 0.1932-0.1803 0.2707c-0.0772 0.0776-0.169 0.1391-0.2701 0.1812-0.1011 0.042-0.2094 0.0638-0.3189 0.0639l-10.146 0.0111-0.3812 0.0185c-0.3608 1.3642-1.6272 2.3692-3.1496 2.3714-1.8266 0.0027-3.3306-1.4375-3.3599-3.2148-0.02899-1.7783 1.4277-3.2218 3.253-3.2237 0.0899 1e-4 0.1798 0.0034 0.2694 0.01l-0.1483-8.9764c-0.0066-0.2314-0.0731-0.4571-0.1928-0.6552-0.1197-0.198-0.2886-0.3618-0.4904-0.4752l-1.7014-0.9829c-0.0949-0.0546-0.178-0.1274-0.2447-0.2142s-0.1157-0.1859-0.1441-0.2916-0.0357-0.216-0.0214-0.32455c0.0142-0.10854 0.0497-0.21321 0.1044-0.30803z" fill="white"/>
</svg>`;

export const MAP_CENTER = [129.757146, 62.030308];

export default function AppStocks() {
    const [searchParams, setSearchParams] = useSearchParams();
    const locations = searchParams.get('locations').split(';');

    const initialMapOptions = {
        center: MAP_CENTER,
        zoom: 15,
        key: '318ef818-7538-4107-b642-6ac8d19e3f55',
        style: '6d92a92b-efd9-4cd0-98d0-4603bf5553ed',
    };

    return (
        <div>
            <div className='App-map-container'>
                <Map2GIS initialMapOptions={initialMapOptions}>
                    {locations.map((stock) => (
                        <HtmlMarker2GIS coordinates={stock.split(',')} anchor={[20, 44]}>
                            <SkladIcon />
                        </HtmlMarker2GIS>
                    ))}
                </Map2GIS>
            </div>
        </div>
    );
}
