import React, {useEffect, useRef} from 'react';
import {load} from '@2gis/mapgl';
import Lottie from 'lottie-react';
import locationAnimation from './../assets/animation/location.json';

export const MAP_CENTER = [129.74058, 62.033012];

const useDebouncedCallback = (func, wait) => {
    const timeout = React.useRef();

    return React.useCallback(
        (...args) => {
            const later = () => {
                clearTimeout(timeout.current);
                func(...args);
            };

            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
        },
        [func, wait]
    );
};

const MapContext = React.createContext([undefined, () => {}]);
const MapProvider = (props) => {
    const [mapInstance, setMapInstance] = React.useState();
    return (
        <MapContext.Provider value={[mapInstance, setMapInstance]}>
            {props.children}
        </MapContext.Provider>
    );
};
const MapWrapper = React.memo(
    () => {
        return <div id="map-container" style={{ width: '100%', height: '100%' }}></div>;
    },
    () => true,
);

const MapIndicator = ({loading}) => {
    const lottieRef = useRef(null);

    useEffect(() => {
        lottieRef.current.setSpeed(2);

        if (loading) {
            lottieRef.current.playSegments([50, 80], true)
        }
        if (!loading) {
            lottieRef.current.playSegments([80, 50], false)
        }
    }, [loading]);

    return <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -48px)`
    }}>
        <Lottie
            animationData={locationAnimation}
            lottieRef={lottieRef}
            loop={false}
            style={{
                width: 64,
                height: 64,
            }}
        />
    </div>
}

const handleGetAddressFrom2GIS = async (coords) => {
    try {
        // return {
        //     "address_name": "203-й микрорайон, 6",
        //     "full_name": "Якутск, 203-й микрорайон, 6",
        //     "id": "7037510072953067",
        //     "name": "203-й микрорайон, 6",
        //     "point": {
        //         "lat": 62.03223,
        //         "lon": 129.759101
        //     },
        //     "purpose_name": "Жилой дом",
        //     "type": "building"
        // }
        const response = await fetch(`https://catalog.api.2gis.com/3.0/items/geocode?lat=${coords[1]}&lon=${coords[0]}&fields=items.point&key=4509f57d-3420-4da4-8428-5d8eb8254cfd`)
        const json = await response.json()
        if (json.meta.code === 200 && json.result.total > 0) {
            const findItem = json.result.items.find( _ => _.type === 'building' && _.hasOwnProperty('address_name'));
            if (findItem)
                return findItem;

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: 'error',
                    data: {
                        message: 'Укажите на карте дом'
                    }
                }))
            } else {
                console.log('debounced data:', {
                    type: 'error',
                    data: {
                        message: 'Укажите на карте дом'
                    }
                })
            }
        }
    } catch (e) {
        console.error(e);
    }
}

async function onPolygonClick({properties}, centerCoordinates) {
    const address = await handleGetAddressFrom2GIS(centerCoordinates);

    if (address) {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'content',
                data: {
                    properties, address
                }
            }))
        } else {
            console.log('debounced data:', {
                type: 'content',
                data: {
                    properties, address
                }
            })
        }
    }
}

window.setMapCenter = ({lon, lat}) => {
    window.myMap.setCenter([lon, lat]);
}

function isPointInPolygon(point, polygon) {
    const x = point[0], y = point[1];
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0], yi = polygon[i][1];
        const xj = polygon[j][0], yj = polygon[j][1];

        const intersect = ((yi > y) !== (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}

const MapGL = () => {
    const [active, setActive] = React.useState(false);

    const onSubmit = useDebouncedCallback((featureAtCenter, centerCoordinates) => {
        onPolygonClick(featureAtCenter, centerCoordinates);
    }, 2000)

    const getGeoJSON = async () => {
        try {
            const response = await fetch('https://rest.loca.store/api/v1/quarters');
            const {data} = await response.json();
            return Promise.resolve(data);
        } catch (e) {
            console.error(e)
            return Promise.reject(false);
        }
    }

    useEffect(() => {
        let map;
        Promise.all([getGeoJSON(), load()]).then(([geoData, mapglAPI]) => {
            if (mapglAPI) {
                map = new mapglAPI.Map('map-container', {
                    center: MAP_CENTER,
                    key: '318ef818-7538-4107-b642-6ac8d19e3f55',
                    styleZoom: 15.8,
                    style: 'd17ca3ec-95fd-4dbd-9862-e4ea7f1e037c',
                    maxBounds: {
                        southWest: [129.707574, 62.019667],
                        northEast: [129.768161, 62.044403],
                    },
                    minZoom: 14,
                    maxZoom: 19,
                    zoomControl: false,
                    disableRotationByUserInteraction: true,
                    disablePitchByUserInteraction: true,
                    disableDragging: false,
                });

                window.myMap = map;

                const source = new mapglAPI.GeoJsonSource(map, {
                    data: geoData,
                })

                const expressZones = {
                    id: `express-zones`, // ID каждого слоя должен быть уникальным

                    // Логика фильтрации или выбора данных для этого слоя
                    filter: [
                        "match",
                        ["get", "deliveryMethod"],
                        ["EXPRESS"],
                        true, // Значение при совпадении атрибута bar источника cо значением "asd"
                        false // Значение при несовпадении
                    ],

                    // Тип объекта отрисовки
                    type: "polygon",

                    // Стиль объекта отрисовки
                    style: {
                        color: "rgba(127, 189, 26, 0.34)",
                        strokeWidth: 2,
                        strokeColor: "rgba(127, 189, 26, 0.5)"
                    }
                };
                const carZones = {
                    id: `car-zones`, // ID каждого слоя должен быть уникальным

                    // Логика фильтрации или выбора данных для этого слоя
                    filter: [
                        "match",
                        ["get", "deliveryMethod"],
                        ["CAR"],
                        true, // Значение при совпадении атрибута bar источника cо значением "asd"
                        false // Значение при несовпадении
                    ],

                    // Тип объекта отрисовки
                    type: "polygon",

                    // Стиль объекта отрисовки
                    style: {
                        color: "rgba(255, 201, 0, 0.34)",
                        strokeWidth: 2,
                        strokeColor: "rgba(255, 201, 0, 0.5)"
                    }
                };

                map.on('styleload', () => {
                    map.addLayer(expressZones);
                    map.addLayer(carZones);

                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            type: 'action',
                            data: {
                                message: 'Геолокация'
                            }
                        }))
                    } else {
                        console.log('debounced data:', {
                            type: 'action',
                            data: {
                                message: 'Геолокация'
                            }
                        })
                    }
                });

                map.on('movestart', () => {
                    setActive(true);
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            type: 'loading',
                            data: {}
                        }))
                    } else {
                        console.log('postMessage: loading')
                    }
                })
                map.on('moveend', (e) => {
                    setActive(false);

                    const centerCoordinates = map.getCenter();

                    const centerPoint = [centerCoordinates[0], centerCoordinates[1]];
                    let featureAtCenter = null;
                    for (const feature of features) {
                        if (feature.geometry.type === 'Polygon') {
                            if (isPointInPolygon(centerPoint, feature.geometry.coordinates[0])) {
                                featureAtCenter = feature;
                                break;
                            }
                        }
                    }

                    if (featureAtCenter) {
                        onSubmit(featureAtCenter, centerCoordinates)
                    } else {
                        if (window.ReactNativeWebView) {
                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                type: 'error',
                                data: {
                                    message: 'В этот район мы пока не доставляем'
                                }
                            }))
                        } else {
                            console.log('В этот район мы пока не доставляем')
                        }
                    }
                })
                const features = source.options.data.features;
            }
        })

        // Удаляем карту при размонтировании компонента
        return () => map && map.destroy();
    }, []);

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <MapWrapper />
            <MapIndicator loading={active} />
        </div>
    );
};

export default function App() {
    return (
        <MapProvider>
            <MapGL />
        </MapProvider>
    );
}
