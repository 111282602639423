import clientSocket from 'socket.io-client';

const API_URL = 'https://geo.loca.store';

export const subscribe = (guid, callbackCoordinates) => {
    const socketConnect = function (room) {
        return clientSocket(API_URL, {
            query: 'guid=' + room,
        });
    };

    const socket = socketConnect(guid);

    socket.on('order.status', (result) => {
        result = JSON.parse(result);
        console.log('order.status:', result);
    });

    socket.on('order.coordinates', (result) => {
        const { result: coordinates } = JSON.parse(result);
        console.log('order.coordinates:', coordinates);
        callbackCoordinates(JSON.parse(coordinates));
    });
};
